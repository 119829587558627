<!--
 * @Author: wxy
 * @Date: 2022-07-05 14:11:41
 * @LastEditTime: 2022-10-24 09:22:29
 * @Description: 服务类型管理
-->
<template>
  <div class="serverTypeManagement">
    <ds-header title="服务类型管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目">
        <!-- <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择所属项目"
          v-model="searchForm.projectId"
        >
          <a-select-option value="">全部项目</a-select-option>
          <a-select-option v-for="item in projectName" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select> -->
        <!-- <el-cascader v-model="searchForm.projectId" style="width: 200px"
              :options="projectName"
              :props="{emitPath:false,checkStrictly: true , label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable></el-cascader> -->
        <a-cascader expandTrigger="hover" style="width: 100%;" v-model="searchForm.projectId"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="projectName"
          :fieldNames="{ label: 'project_name', children: 'child', value: 'id' }"
          placeholder="请选择供所属项目" />
      </a-form-item>
      <a-form-item label="状态">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择状态"
          v-model="searchForm.serverTypeStatus"
        >
          <a-select-option value="">全部状态</a-select-option>
          <a-select-option v-for="item in serverStatus" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="服务供应商">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择服务供应商"
          v-model="searchForm.supplierId"
        >
          <a-select-option value="">全部服务供应商</a-select-option>
          <a-select-option v-for="item in supplierList" :key="item.supplierId" :value="item.supplierId">
            {{ item.supplierName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button>
      </span>
    </a-form>
    <div style="margin: 10px 0">
      <a-button type="primary" @click="add"> <a-icon type="plus" /> 新增服务类型 </a-button>
    </div>
    <a-table
      :rowKey="(record, index) => index"
      :columns="table.columns"
      :data-source="table.tableData"
      :pagination="table.pagination.total ? table.pagination : false"
      @change="pageChange"
      bordered
      :scroll="{ x: 1500 }"
    >
      <div slot="projectName" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.projectName }}</span>
          </template>
          <div
            :style="{
              width: '95%',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            {{ text.projectName }}
          </div>
        </a-tooltip>
      </div>
      <div slot="supplierNames" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.supplierNames }}</span>
          </template>
          <div
            :style="{
              width: '95%',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            {{ text.supplierNames }}
          </div>
        </a-tooltip>
      </div>
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="isEnabled(record)" class="btn">
          {{ record.serverTypeStatus === 0 ? "禁用" : "启用" }}</a-button
        >
        <a-button type="link" @click="toDetail(record)" class="btn"> 查看</a-button>
        <a-button type="link" @click="edit(record)" class="btn"> 编辑</a-button>
        <a-button type="link" @click="deleteServerType(record)" style="color: #f5222d" class="btn"> 删除</a-button>
      </span>
    </a-table>
  </div>
</template>

<script>
import pageRules from "@/utils/pagingConfig/pageUtils.js";
import * as api from "@/api/entrepreneurClubManagement/serverManagement";
export default {
  name: "serverTypeManagement",
  data() {
    return {
      // 查询条件
      searchForm: {
        // 所属项目
        projectId: [],
        // 状态
        serverTypeStatus: "",
        // 供应商
        supplierId: "",
      },
      table: {
        columns: [
          {
            title: "服务类型名称",
            dataIndex: "serverTypeName",
            key: "serverTypeName",
            width: 180,
          },
          {
            title: "所属项目",
            key: "projectName",
            width: 280,
            scopedSlots: { customRender: "projectName" },
          },
          {
            title: "服务供应商",
            key: "supplierNames",
            width: 280,
            scopedSlots: { customRender: "supplierNames" },
          },
          {
            title: "创建人",
            dataIndex: "createIdShow",
            key: "createIdShow",
            width: 150,
          },

          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            width: 180,
          },
          {
            title: "最后修改人",
            dataIndex: "modifiedIdShow",
            key: "modifiedIdShow",
            width: 150,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "最后修改时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            width: 180,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "状态",
            dataIndex: "serverTypeStatus",
            key: "serverTypeStatus",
            width: 120,
            customRender(text) {
              return text == 1 ? "已禁用" : "已启用";
            },
          },
          {
            title: "操作",
            key: "action",
            width: "300px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        tableData: [],
        pagination: {
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      // 状态
      serverStatus: [
        {
          value: 0,
          label: "已启用",
        },
        {
          value: 1,
          label: "已禁用",
        },
      ],
      // 项目
      projectName: [],
      // 供应商
      supplierList: [],
    };
  },
  // 进入路由添加缓存
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("operationCachedRoutes/updateAliveRoutes", ["serverTypeManagement"]);
      if (from.query && from.query.id) {
        vm.getList();
      }
    });
  },
  // 离开清除缓存
  beforeRouteLeave(to, from, next) {
    if (
      to.path !== "/entrepreneurClubManagement/serverManagement/serverTypeManagement/serverTypeDetail" &&
      to.path !== "/entrepreneurClubManagement/serverManagement/serverTypeManagement/serverTypeAdd"
    ) {
      this.$store.dispatch("operationCachedRoutes/updateAliveRoutes", []);
    } else if (
      to.path === "/entrepreneurClubManagement/serverManagement/serverTypeManagement/serverTypeAdd" &&
      to.query.type === "add"
    ) {
      this.$store.dispatch("operationCachedRoutes/updateAliveRoutes", []);
    }
    next();
  },
  mounted() {
    // 获取列表数据
    this.getList();
    // 获取所属项目
    this.getProject();
    // 获取供应商
    this.getSupplier();
  },
  methods: {
    // 查询
    search() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    // 重置
    resetSearchForm() {
      this.searchForm = {
        // 所属项目
        projectId: [],
        // 状态
        serverTypeStatus: "",
        // 供应商
        supplierId: "",
      };
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    // 获取列表
    async getList() {
      try {
        const data = {
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
          ...this.searchForm,
          projectId:this.searchForm.projectId.length?this.searchForm.projectId[this.searchForm.projectId.length-1]:''
        };
        const res = await api.serverTypeList(data);
        if (res.code === "200") {
          this.table.tableData = res.data.list;
          this.table.pagination.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },

    // 新增
    add() {
      this.$router.push({
        path: "/entrepreneurClubManagement/serverManagement/serverTypeManagement/serverTypeAdd",
        query: {
          type: "add",
        },
      });
    },
    // 编辑
    edit(record) {
      this.$router.push({
        path: "/entrepreneurClubManagement/serverManagement/serverTypeManagement/serverTypeAdd",
        query: {
          type: "edit",
          id: record.serverTypeId,
        },
      });
    },
    // 查看
    toDetail(record) {
      this.$router.push({
        path: "/entrepreneurClubManagement/serverManagement/serverTypeManagement/serverTypeDetail",
        query: {
          id: record.serverTypeId,
        },
      });
    },
    // 切换分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 删除
    deleteServerType(record) {
      const _this = this;
      _this.$confirm({
        title: "确认删除",
        content: "删除后该数据无法恢复，确认删除吗？",
        async onOk() {
          try {
            const res = await api.serverTypeDelete(record.serverTypeId);
            if (res.code === "200") {
              _this.table.pagination.current = pageRules.pageRules(
                _this.table.tableData.length,
                1,
                _this.table.pagination.current
              );
              _this.$message.success("删除成功");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {}
        },
        onCancel() {},
      });
    },
    // 禁用/启用
    isEnabled(record) {
      const _this = this;
      let title;
      let content;
      let serverTypeStatus = 0;
      if (record.serverTypeStatus === 1) {
        title = "确认启用该服务类型";
        content = "";
        serverTypeStatus = 0;
      } else {
        title = "确认禁用该服务类型";
        content = "";
        serverTypeStatus = 1;
      }
      _this.$confirm({
        title: title,
        content: content,
        async onOk() {
          try {
            const res = await api.serverTypeEnable(record.serverTypeId, serverTypeStatus);
            if (res.code === "200") {
              _this.$message.success("操作成功");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {}
        },
        onCancel() {},
      });
    },
    // 获取所属项目
    async getProject() {
      try {
        const res = await api.getShunyiAllProject();
        if (res.code === "200") {
          this.projectName = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
    // 获取供应商
    async getSupplier() {
      try {
        const res = await api.getSupplier();
        if (res.code === "200") {
          this.supplierList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang='scss' scoped>
.btn {
  padding: 4px;
}
</style>